// 
// maps.scss
//

//Google Maps

.gmaps,
.gmaps-panaroma {
  height: 300px;
  background: $gray-100;
  border-radius: 3px;
  position: relative;
}


// Vector Map 

.jvectormap-label {
  border: none;
  background: $gray-800;
  color: $gray-100;
  font-family: $font-family-base;
  font-size: $font-size-base;
  padding: 5px 8px;
}