.span-block-father {
    border: 1px solid red !important;

}

.status-schedule-select {
    /* color: red !important; */
    font-size: 10pt;
}


.status-schedule-select .yellow {
    color: #F8BE15 !important;
}

.status-schedule-select .red {
    color: #DC1F2E !important;
}

.status-schedule-select .blue {
    color: #66ccff !important;
}

.status-schedule-select .green {
    color: #6EBE49 !important;
}

.status-schedule-select .gray {
    color: #717171 !important;
}

.status-schedule-select {
    -moz-appearance: #000 !important;
    /* Firefox */
    -webkit-appearance: #000 !important;
    /* Safari and Chrome */
    appearance: #000 !important;
}

.react-datepicker-ignore-onclickoutside {
    font-size: 10pt;
}

.react-datepicker__input-container input {
    font-size: 10pt;

}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(110, 190, 73, 0.05) !important;
}

.table-loading {
    /* position: relative; */
}

.table-loading::before {
    opacity: 1;

    content: "";

    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;


    /* width: 96%; */
    /* height: 100%; */

    background-color: rgba(255, 255, 255, 0.8);
    z-index: 100;
}

.table-loading::after {
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;



    width: 100%;
    height: 100%;

    inset: 0;

    content: url('./spinner.gif');
}


.modal-header-name {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #FFFFFF;

    margin-left: 60px;
}

.modal-header-companyname {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    margin-left: 60px;

}

.modal-header-custom {
    height: 123px;
    background: linear-gradient(180deg, #CFFA55 -15.12%, #7FC94B 67.44%, #6EBE49 100%);
    box-shadow: 0px 0px 35px rgba(154, 161, 171, 0.14902);
    border-radius: 4px;

    display: flex;
    /* justify-content: center; */
    align-items: center;
}

.modal-custom-title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
    color: #6EBE49;

}

.modal-custom-label {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 30px;
    color: #868589;
}

.modal-custom-item {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #868589;

    text-transform: capitalize;
}

.modal-custom-link {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 30px;
    text-decoration-line: underline;
    color: #6EBE49;
    cursor: pointer;
}

.modal-custom-back {
    font-style: normal;
    font-weight: 500;
    font-size: 16.4px;
    line-height: 17px;
    /* identical to box height */


    color: #6EBE49;

    cursor: pointer;

    margin-bottom: 20px;
}

.answers-label {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    /* margin-top: 20px; */
    padding-top: 20px;


}

.answers-item {

    display: flex;
    align-items: flex-end;
    height: 100%;
    padding-top: 20px;


}

.custom-modal-segment {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 15px;
    /* or 133% */


    /* Seagate Gray */

    color: #6EBE49;

}

.answer-resp-array {
    /* border: 1px solid red; */
}


#performance-tracker .card-body div#schedules-table,
.card-body {
    display: flex;
    flex-direction: column;
}

#performance-tracker .card-body.change-order div#schedules-table div.alert.alert-primary,
div.row:not(#revendas) .card-body.change-order .alert.alert-primary.fade.show {
    order: 2;
}

#performance-tracker .card-body.change-order div#schedules-table div.react-bootstrap-table.table-responsive,
div.row:not(#revendas) .card-body.change-order .react-bootstrap-table.table-responsive {
    order: 3;
}

#performance-tracker .card-body.change-order div#schedules-table div.row.react-bootstrap-table-pagination {
    order: 1;
}

.react-bs-table-sizePerPage-dropdown.dropdown ul li a {
    width: 100%;
    display: block;
}

.row.react-bootstrap-table-pagination {
    align-items: center;
}

.nav-to-center {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
}

.nav-to-center .page-title-right {
    margin: 0;
}

.custom-order-resales-filter #page-options-row .page-title-box .page-title-right > *:nth-child(2) {
    order: 1;
    margin: 0 !important;
}

.custom-order-resales-filter #page-options-row .page-title-box .page-title-right > *:not(:nth-child(2)) {
    order: 3
}

.custom-order-resales-filter #page-options-row .page-title-box .page-title-right > *:nth-child(1) {
    order: 2;
    margin-left: 0.375rem !important;
}

.badge-warning-lighten {
    color: #DDA500 !important;
    background-color: #DDA5002e !important;
}

.custom-order-resales-filter.col {
    z-index: 2;
}

.dropdown-container {
    height: 36px;
    border: 1px solid #dee2e6 !important;
    border-radius: 3px !important;;
    color: #868589 !important;;
}

.rmsc .dropdown-container:focus-within {
    box-shadow: none !important;
}
.rmsc .dropdown-heading {
    height: 100% !important;
}
.rmsc .gray {
    color: #868589 !important;
}

.container-answers {
    counter-increment: item 1;
}

.container-answers .modal-custom-title {
    position: relative;
}

.container-answers .modal-custom-title::before {
    content: counter(item) ".";
    margin-right: 5px;
    text-align: right;
}