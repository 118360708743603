@charset "UTF-8";

$bodyColor: #f0f0f0;
$greenColor: #6ebe49;

.bg-primary,
.main-green-bg {
	background: linear-gradient(180deg, #dbff76 0%, $greenColor 100%), #ffffff !important;
	border: none !important;
	color: white !important;
	&.btn-secondary:disabled {
		background: lightgray !important;
	}
}

.submit-form-group {
	display: flex;
	justify-content: center;
	.btn {
		border: none;
	}
}

// logo
.logo-section {
	// background: red;
	text-align: center;
	padding-bottom: 20px;
}

.logo-img {
	height: auto;
	width: 40%;
}

// body
body.authentication-bg {
	background-image: url("../../../images/new/login-background.png");
	background-repeat: no-repeat;
	background-size: cover;
}

// footer{
.footer-options {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 80px;
}

.logo-icon {
	height: auto;
	width: 20%;
	margin-bottom: 10px;
}

// Input password
.remove {
	display: none;
}

.password-input {
	display: flex;
	background: #ffffff;
	border: 1px solid #e1e1e1;
	border-radius: 3px;
	cursor: pointer;
}

.password-input > input {
	min-width: 85%;
	border: none;
}

.eye {
	background: #edeeee;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
}

// card
.p-4 {
	padding-top: 20px !important;
}

// recuperar senha
.text-muted.ml-1 {
	color: $greenColor !important;
}

// painel lateral
li.mm-active > a > span {
	color: #c4f142 !important;
}
li:not(.mm-active) > a.has-arrow span.pending-father {
	position: relative;
	&:after {
		content: "";
		background: #dc1f2e;
		width: 5px;
		height: 5px;
		border-radius: 10px;
		position: absolute;
		top: 50%;
		margin-left: 10px;
		transform: translate(0, -50%);
	}
}

body[data-leftbar-theme="dark"] .left-side-menu {
	background: linear-gradient(176.67deg, #656565 -3.45%, #404040 35.5%, #2c2c2c 100%), #ffffff !important;
	padding-top: 100px;
	img {
		width: 70%;
		margin-top: 20px;
		height: auto;
	}
	.logo {
		background: none !important;
	}
}

.green-logo {
	max-width: 70px;
}

.form-group .react-datepicker-wrapper:first-child {
	margin-right: 10px;
	.input-group-append {
		display: none;
	}
}

// - remoção dos links extras
ul.metismenu.side-nav > li:nth-child(3) li:nth-child(2) ~ li,
ul.metismenu.side-nav > li:nth-child(4) li:nth-child(2) ~ li {
	display: none;
}

// Consultores
.badge-danger {
	background: rgba(220, 31, 46, 0.15);
	color: #dc1f2e;
}

.badge-success {
	background: #6ebe493a;
	color: $greenColor;
}

.badge-canceled {
	background: #7171713a;
	color: #717171;
}

.badge-schedule {
	background: #66ccff3a;
	color: #66ccff;
}

.badge-pending {
	background: #f8bf153a;
	color: #f8be15;
}
.legend-dot {
	position: relative;
	&.red {
		&::before {
			content: "";
			width: 8px;
			height: 8px;
			position: absolute;
			border-radius: 10px;
			top: 50%;
			left: -5px;
			transform: translateY(-50%);
			background-color: #dc1f2e;
		}
	}
	&.yellow {
		&::before {
			content: "";
			width: 8px;
			height: 8px;
			position: absolute;
			border-radius: 10px;
			top: 50%;
			left: -5px;
			transform: translateY(-50%);
			background-color: #f8be15;
		}
	}
	&.green {
		&::before {
			content: "";
			width: 8px;
			height: 8px;
			position: absolute;
			border-radius: 10px;
			top: 50%;
			left: -5px;
			transform: translateY(-50%);
			background-color: $greenColor;
		}
	}
}

.success-color {
	color: $greenColor;
}

.color-white {
	color: #fff !important;
}

.danger-color {
	color: #dc1f2e;
}

.page-link {
	border: none;
	margin: 0 3px;
	border-radius: 100px;
}

.page-item.active .page-link,
.bg-success,
.btn-success {
	background: $greenColor !important;
}

.btn-success {
	border: none;
}

.bg-danger,
.btn-danger {
	background: #dc1f2e !important;
	option {
		background: white !important;
	}
	&.badge {
		padding-top: 0.3rem;
	}
}

.page-link {
	border: none;
	margin: 0 3px;
	border-radius: 100px !important;
}
button.action-icon-container {
	border: none;
	background-color: transparent;
	padding: 0;
	span.action-icon {
		cursor: pointer;
	}
	&:disabled {
		span.action-icon {
			cursor: default;
			opacity: 0.5;
			&:hover {
				color: #98a6ad;
			}
		}
	}
}

.flex {
	display: flex;
	&.-end {
		justify-content: flex-end;
	}
}

.vertical-center {
	align-items: center;
}

a {
	color: $greenColor;
	&.defaultColor {
		color: #6c757d;
	}
}

.tilebox-one {
	font-family: Helvetica !important;
}

.color-blue {
	color: #00a1dd;
}

.color-waring {
	color: #f8be15;
}

.color-deepBlue {
	color: #008f98;
}

.color-orange {
	color: #ff5000;
}
.color-gray {
	color: #868589;
}

// consultores fim
// revendas
.bg-degradeBlue {
	border: none;
	background: linear-gradient(180deg, #45dce5 0%, #008f98 100%) !important;
	box-shadow: 0px 2px 6px rgba(0, 143, 152, 0.5);
	color: white;
}

.bg-deepBlue {
	background-color: #008f98;
	color: white;
}

.white-link {
	color: white !important;
	&:hover {
		color: white !important;
	}
}

.revendas-modal h5 {
	width: 100%;
}

.revenda-modal-btn svg {
	width: 15px;
	height: 15px;
	margin-bottom: 4px;
	margin-right: 5px;
}

.revendas-modal .close {
	display: none;
}

.pad-row {
	width: 95%;
	margin: auto;
}

// questionário
.no-shadow {
	box-shadow: none;
}

.bordered {
	background: #ffffff;
	border: 1px solid #dee2e6;
	border-radius: 3px;
	color: #868589;
}

.min-pad {
	padding: 1rem;
}

.color-success {
	color: #6ebe49;
}

// check das tables
.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
	color: $greenColor;
	border-color: $greenColor;
	background-color: $greenColor;
}

// remoção do background de th
.table .thead-light th {
	background: white !important;
}

// alutra dos cards
.card-equal-h {
	display: flex;
	.card,
	.card-group {
		width: 100%;
	}
	.row {
		height: 100%;
		width: 100%;
	}
}

.card-group > .card {
	margin-bottom: 24px;
}

// loading data
.loadingData {
	background: #0000001f;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 100;
	left: 0;
	span {
		position: fixed;
		left: calc(50% - (133px / 2));
		top: calc(50% - (133px / 2));
	}
}

.lh-15 {
	line-height: 1.5rem;
}

// removeInput
.remove-input {
	cursor: pointer;
	position: absolute;
	top: 65%;
	right: 3%;
}

// consultor responsavel
tr {
	.hidden-input input {
		border-color: transparent;
	}
	& :hover input {
		border-color: rgb(222, 226, 230);
	}
	&:nth-child(odd) {
		.hidden-input input {
			background: #f1f3fa;
			&:focus,
			&:hover {
				background: white;
			}
		}
	}
}

.rbt-token {
	border: 0;
	border-radius: 0.25rem;
	display: inline-block;
	line-height: 1em;
	margin: 1px 3px 2px 0;
	padding: 4px 7px;
	position: relative;
	background-color: rgba(114, 124, 245, 0.2) !important;
	color: #727cf5 !important;
	.rbt-token-remove-button {
		bottom: 0;
		color: inherit;
		font-size: inherit;
		font-weight: normal;
		opacity: 1;
		outline: none;
		padding: 3px 7px;
		position: absolute;
		right: 0;
		text-shadow: none;
		top: -2px;
	}
}

.rbt-input-multi .rbt-input-wrapper {
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: -4px;
	margin-top: -1px;
	overflow: hidden;
}

.rbt-token-removeable {
	cursor: pointer;
	padding-right: 21px;
}

#calendar * {
	scrollbar-width: thin;
	scrollbar-color: $greenColor #f0f0f0;
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background: #f0f0f0;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $greenColor;
	}
}

#revendas {
	td {
		&:not(:first-of-type) {
			padding: 0 !important;
			position: relative;
		}
	}
	border-top: none !important;
	#search-bar {
		label,
		input {
			min-width: 20%;
		}
	}
}
td {
	vertical-align: middle !important;
}

.pointer {
	cursor: pointer;
}

.aqui {
	outline: 2px solid tomato;
}
.no-marg-car {
	.card {
		margin-bottom: 0 !important;
	}
}
.btn-pad {
	padding: 0.45rem 0.9rem;
}
.costumized-datePicker-icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	bottom: 0;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
}
.w-180p {
	min-width: 200px;
	text-align: left;
	button {
		text-align: left;
	}
}
.h-45p {
	min-height: 45px;
}
.nav-to-right {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	flex-wrap: wrap;
	.page-title {
		margin-right: 20px;
	}
}

.responsive-sub-links {
	display: none;
	right: 0;
	position: absolute;
	z-index: 1;
	left: 70px;
	background: #666;
	// li {
	// 	&:hover {
	// 		background: $greenColor;
	// 	}
	// }
	// background: #727cf5;
	width: 0px;
	li {
		padding: 10px 0;
		a {
			color: white !important;
		}
		&:last-child {
			padding-bottom: 15px;
		}
	}
}
.side-nav-item:hover {
	.responsive-sub-links {
		width: auto;
	}
}
.date-picker {
	display: flex;
}

@media (max-width: 992px) {
	.remove-md {
		display: none;
	}
	.fixed-page-header-ghost {
		display: none;
	}
}
@media (min-width: 993px) {
	.remove-lg {
		display: none;
	}
	.fixed-page-header-ghost {
		min-height: 95px;
	}
}
@media (max-width: 1600px) {
	.nav-to-right {
		flex-direction: column-reverse !important;
		.page-title-right {
			margin-top: -20px;
		}
	}
	.date-picker {
		display: block;
	}
	.fixed-page-header-ghost {
		min-height: 130px;
	}
}
@media (max-width: 1366px) {
	.left-side-menu > div > ul {
		position: fixed;
		top: 70px;
	}
	.responsive-sub-links {
		display: block;
	}
}
@media (min-width: 1029px) {
	.fixed-page-header {
		position: fixed;
		left: 70px;
		z-index: 4;
		background: $bodyColor;
		right: 0px;
		top: 70px;
		padding: 0 12px 0 24px;
		#page-options-row .page-title-right {
			transform: translateY(50%);
		}
	}
}
@media (min-width: 1367px) {
	.fixed-page-header {
		position: fixed;
		left: 260px;
		z-index: 4;
		background: $bodyColor;
		right: 0px;
		top: 70px;
		padding: 0 12px 0 24px;
		#page-options-row .page-title-right {
			transform: translateY(50%);
		}
	}
}
.no-box-shadow {
	box-shadow: none !important;
}

.inValidCheck:checked + label {
	background-color: #dc1f2e;
	color: white;
	border-color: #dc1f2e;
}
.validCheck:checked + label {
	background-color: $greenColor;
	color: white;
	border-color: $greenColor;
}
.select-model {
	border-radius: 2px;

	option {
		color: #868589;
		cursor: pointer;
		&:hover {
			background-color: red;
		}
	}
}
.radio-btns {
	// width: 50%;
	// max-width: 500px;
	// display: flex;
	height: 37px;
	background: linear-gradient(0deg, #f0f0f0, #f0f0f0);
	.validCheck {
		&:checked + label {
			background: linear-gradient(180deg, #dbff76 0%, $greenColor 100%), #ffffff !important;
		}
	}
	label {
		// width: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		transition-duration: 0.2s;
		&:hover {
			background-color: #ddd;
		}
	}
}
.profile-pic {
	width: 48px;
	height: 48px;
	border-radius: 50%;
}
.branding-pic {
	max-height: 100px;
	border-radius: 4px;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
}
.validBg {
	background: #bedc70 !important;
}
.inValidBg {
	background: #dfa9a6 !important;
}
.transition {
	transition-duration: 0.3s;
}
.branding * {
	scrollbar-color: $greenColor #f0f0f0;
	&::-webkit-scrollbar {
		height: 10px;
	}
	&::-webkit-scrollbar-track {
		background: #f0f0f0;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $greenColor;
	}
}
#branding .react-bootstrap-table table {
	table-layout: fixed;
}

#leads {
	tr {
		transition-duration: 0.3s;
		td {
			padding: 0 !important;
			span {
				padding: 0 0.95rem !important;
				display: flex !important;
				align-items: center !important;
				height: 70px !important;
			}
		}
	}
}
.select-model:focus {
	outline: none;
}

.select-withBtn {
	position: relative;
	height: 36px !important;
}

select::-ms-expand {
	display: none;
}

.select-withBtn:after {
	content: "";
	display: flex;
	color: white;
	background-image: url("../../../svg/calendar.svg"), linear-gradient(180deg, #dbff76 0%, $greenColor 100%);
	top: 0px;
	background-position: center;
	background-repeat: no-repeat;
	bottom: 01px;
	right: 0;
	padding: 0 15px;
	width: 10px;
	align-items: center;
	position: absolute;
	pointer-events: none;
}

.select-withBtn select:not(.show-arrow) {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	display: block;
	float: right;
}
g.apexcharts-xaxis {
	text-transform: capitalize;
}
.w-200 {
	min-width: 200px;
}
.w-550px {
	max-width: 550px;
}
.dropdown-menu {
	max-height: 150px !important;
}

.dropdown-modal {
	.dropdown-menu {
		max-height: max-content !important;
		width: 275px !important;
		padding: 0.75rem 1rem;
	}
	.dropdown-toggle {
		&::after,
		&::before {
			display: none !important;
		}
	}
}
.full-height .dropdown-menu {
	max-height: max-content !important;
}
.quest-details {
	font-family: Helvetica;
	dt {
		font-weight: 500;
	}
}
.dark-color {
	color: #2c2c2c;
}
.w-max {
	width: max-content;
}

.w-min-120 {
	min-width: 120px;
}

.title-container {
	max-width: 525px;
	margin: auto;
}
.answers-container {
	max-width: 520px;
	margin: auto;
}
.card .card-icons-container {
	position: absolute;
	right: 10px;
	top: 20px;
	cursor: default;
	display: flex;
	z-index: 3;
	span.card-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #666;
		// background: #eee;
		border-radius: 100%;
		// width: 20px;
		margin-left: 10px;
		height: 20px;
	}
}

body {
	background-color: $bodyColor;
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: white;
}

.multiple-desc {
	display: flex;
	flex-wrap: wrap;
}
.w-fit {
	max-width: fit-content;
}
.w-30p {
	max-width: 30px;
}
.select-withBtn {
	select.show-arrow {
		margin-right: 29px;
		width: 20px;
	}
}
.custom-icon span:hover {
	filter: brightness(0.7);
}

.branding-carousel {
	background: #ddd;
	img {
		object-fit: contain;
		height: 80vh;
	}
}
.performance-table {
	tr {
		td {
			padding: 5px 10px;
			min-width: 90px;
			&.green-color {
				background: rgba(110, 190, 73, 0.3);
			}
			&.yellow-color {
				background: rgba(248, 190, 21, 0.3);
			}
			&.red-color {
				background: rgba(220, 31, 46, 0.25);
			}
		}

		&:nth-child(even) {
			background: rgba(108, 117, 125, 0.15);
		}
	}
}
.performance-tracker-container {
	overflow-y: auto;
	height: 65vh;
	scrollbar-width: thin;
	scrollbar-color: $greenColor #f0f0f0;
	&::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}
	&::-webkit-scrollbar-track {
		background: #f0f0f0;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $greenColor;
	}

	.performance-tracker-content-header {
		position: sticky;
		top: 0;
		background: white;
	}

	table:not(:last-child) {
		margin-bottom: 1.5rem;
	}
	.border {
		outline: 2px solid $greenColor;
	}
	td,
	th {
		&.clamp-td {
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
		&.force-td-width {
			h6 {
				margin: 0;
			}
			width: 90px;
			padding: 0.6rem;
			height: calc(31.61px * 2);
		}
		&.force-width {
			max-width: 90px;
		}
	}
	span.force-width {
		width: 100%;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}
.light-green-bg {
	background: linear-gradient(180deg, #e5ff9c 0%, #bcea38 100%) !important;
}
.h-125 {
	height: 125px;
}
.w-355 {
	width: 355px;
}
.min-w-main-table-data-col {
	min-width: calc(90px * 7);
}

.card {
	&.default-card {
		&.warning-card {
			background: linear-gradient(180deg, #f9525f 0%, #dc1f2e 100%), #ffffff;
		}
		&.valid-card {
			background: linear-gradient(180deg, #dbff76 -39.63%, #6ebe49 100%), #ffffff;
		}
		h3,
		h5,
		p {
			color: white !important;
			position: relative;
		}
		h3 {
			&:before {
				content: "Você tem";
				position: absolute;
				font-size: 13px;
				top: -17px;
				font-weight: 400;
				font-family: Helvetica;
			}
		}
		.widget-icon {
			color: white;
			background: rgba(255, 255, 255, 0.25);
		}
	}
}
.w-70 {
	width: 70%;
}
.break-card-title {
	max-width: calc(100% - 60px);
}
.info-icon {
	background-color: #eee;
	border-radius: 20px;
	width: 20px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
	filter: contrast(0.1);
}

#performance-tracker div#title-col td {
	height: 31.61px;
}

.clamp-table {
	td {
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}
.action-card-info {
	position: absolute;
	display: flex;
	justify-content: center;
	right: 10px;
}
.clamp-line {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

li.has-active-children > a > span {
	color: #c4f142 !important;
}

.results-table-container {
	max-width: 100%;
	overflow: auto;
	padding: 4px;
}
.raw-table-card {
	height: 100%;
	max-width: 100%;
	overflow: auto;
}

.results-table {
	width: 100%;
	height: 100%;
	&.large-titles tr td:first-of-type {
		min-width: 12rem;
	}
	th {
		background: linear-gradient(180deg, #dbff76 0%, #6ebe49 100%), #ffffff !important;
		color: white;
	}
	tr {
		&:nth-child(odd) {
			background: #f1f3fa;
		}
		&:nth-child(even) {
			background: #fff;
		}
	}
	td,
	th {
		padding: 0.5rem;
		min-width: 5rem;
	}
	&.no-wrap {
		td,
		th {
			white-space: nowrap;
		}
	}
}
.transparentBg {
	background-color: transparent !important;
	box-shadow: unset !important;
}
.exporting-table {
	th {
		background: #7dd853 !important;
		white-space: normal !important;
	}
}

.selectYear {
	margin-left: 6px;
}

span.date-picker-start-end {
	position: absolute;
	top: 2px;
}
.goals-container span.date-picker-start-end {
	position: absolute;
	top: -19px;
}